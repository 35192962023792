import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Input, message, Tabs } from "antd";
import { fetchRecords, updateRecord, createRecord, deleteRecord } from "../api";
import {
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { TabPane } = Tabs;

const RecordList = ({ user, collapsed }) => {
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [form] = Form.useForm();

  useEffect(() => {
    const getRecords = async () => {
      const records = await fetchRecords();
      setRecords(records);
    };
    getRecords();
  }, []);

  const showModal = (record, editing = false, adding = false) => {
    setSelectedRecord(record);
    setIsEditing(editing);
    setIsAdding(adding);
    form.setFieldsValue(record || {});
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
    setIsEditing(false);
    setIsAdding(false);
    form.resetFields();
  };

  const handleUpdate = async (values) => {
    try {
      await updateRecord(selectedRecord.id, values);
      message.success("记录更新成功");
      setIsModalVisible(false);
      const updatedRecords = await fetchRecords();
      setRecords(updatedRecords);
    } catch (error) {
      message.error("更新失败,请重试");
    }
  };

  const handleAdd = async (values) => {
    if (!values.姓名 || values.姓名.trim() === "") {
      message.error("请填写姓名！");
      return;
    }
    try {
      await createRecord(values);
      message.success(`成功添加一条“${values.姓名}”的信息`);
      setIsModalVisible(false);
      const updatedRecords = await fetchRecords();
      setRecords(updatedRecords);
    } catch (error) {
      message.error("添加失败,请重试");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRecord(selectedRecord.id);
      message.success(`成功删除“${selectedRecord.姓名}”的数据`);
      setIsDeleteModalVisible(false);
      const updatedRecords = await fetchRecords();
      setRecords(updatedRecords);
    } catch (error) {
      message.error("删除失败,请重试");
    }
  };

  const handleSearch = (value) => {
    setSearchKeyword(value);
    if (value) {
      const filtered = records.filter((record) =>
        Object.values(record).some((field) =>
          field?.toString().toLowerCase().includes(value.toLowerCase())
        )
      );
      const results = filtered.map((record) => {
        const matches = Object.entries(record)
          .filter(([key, val]) =>
            val?.toString().toLowerCase().includes(value.toLowerCase())
          )
          .map(([key, val]) => `${key}：${val}`);
        return { ...record, matches };
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchModalOpen = () => {
    setIsSearchModalVisible(true);
  };

  const handleSearchModalClose = () => {
    setIsSearchModalVisible(false);
  };

  const handleSelect = (record) => {
    showModal(record, user === "admin");
    handleSearchModalClose();
  };

  const columns = [
    { title: "姓名", dataIndex: "姓名", key: "姓名", width: "20%" },
    { title: "入伍时间", dataIndex: "入伍时间", key: "入伍时间", width: "20%" },
    { title: "院系", dataIndex: "院系", key: "院系", width: "20%" },
    { title: "入伍部队", dataIndex: "入伍部队", key: "入伍部队", width: "20%" },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <div className="space-x-2">
          <Button
            onClick={() => showModal(record, false)}
            icon={<EyeOutlined />}
            className="bg-blue-400 hover:bg-blue-500 text-white font-semibold py-1 px-2 rounded transition duration-300 ease-in-out"
          >
            查看
          </Button>
          {user === "admin" && (
            <>
              <Button
                onClick={() => showModal(record, true)}
                icon={<EditOutlined />}
                className="bg-green-400 hover:bg-green-500 text-white font-semibold py-1 px-2 rounded transition duration-300 ease-in-out"
              >
                编辑
              </Button>
              <Button
                onClick={() => {
                  setSelectedRecord(record);
                  setIsDeleteModalVisible(true);
                }}
                icon={<DeleteOutlined />}
                className="bg-red-400 hover:bg-red-500 text-white font-semibold py-1 px-2 rounded transition duration-300 ease-in-out"
              >
                删除
              </Button>
            </>
          )}
        </div>
      ),
      width: "20%",
    },
  ];

  const renderInfoFields = (fields) => (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      {fields.map((field) => (
        <div key={field} className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1">
            {field}
          </label>
          {isEditing || isAdding ? (
            <Form.Item name={field} className="mb-0">
              <Input className="rounded-md" />
            </Form.Item>
          ) : (
            <div className="bg-gray-50 p-2 rounded-md text-gray-800">
              {selectedRecord?.[field] || "无"}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const modalContent = (
    <Form
      form={form}
      onFinish={isAdding ? handleAdd : handleUpdate}
      layout="vertical"
      className="h-full flex flex-col"
    >
      <div className="flex-grow overflow-y-auto pr-4">
        <Tabs defaultActiveKey="1">
          <TabPane tab="基本信息" key="1">
            {renderInfoFields([
              "入伍时间",
              "姓名",
              "院系",
              "学号",
              "籍贯",
              "民族",
              "政治面貌",
              "生日",
              "备注",
            ])}
          </TabPane>
          <TabPane tab="服役情况" key="2">
            {renderInfoFields(["入伍部队", "获奖情况", "联系人", "慰问情况"])}
          </TabPane>
          <TabPane tab="在校情况" key="3">
            {renderInfoFields([
              "升学去向",
              "专业",
              "在校学工",
              "实习实践经历",
              "在校荣誉",
            ])}
          </TabPane>
          <TabPane tab="就业情况" key="4">
            {renderInfoFields([
              "毕业院系",
              "毕业专业",
              "毕业学历",
              "毕业年份",
              "就业去向",
              "职务/职称",
            ])}
          </TabPane>
          <TabPane tab="联系方式" key="5">
            {renderInfoFields(["现居住地", "电话", "微信"])}
          </TabPane>
        </Tabs>
      </div>
      {(isEditing || isAdding) && (
        <Form.Item className="mt-4 mb-0 flex-shrink-0">
          <Button
            type="primary"
            htmlType="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            保存
          </Button>
        </Form.Item>
      )}
    </Form>
  );

  const highlightMatch = (text, keyword) => {
    const regex = new RegExp(`(${keyword})`, "gi");
    return text.replace(
      regex,
      (match) => `<span class="text-blue-600 font-bold">${match}</span>`
    );
  };

  const searchModalContent = (
    <div>
      <Input
        placeholder="搜索记录"
        className="p-2 border rounded-md w-full mb-4"
        onChange={(e) => handleSearch(e.target.value)}
      />
      <div className="max-h-96 overflow-y-auto">
        {searchResults.map((record) => (
          <div
            key={record.id}
            className="p-2 bg-gray-50 border border-gray-300 rounded-md mb-2 cursor-pointer"
            onClick={() => handleSelect(record)}
          >
            <div className="font-bold text-lg">
              {record.姓名}，{record.入伍时间}入伍，{record.入伍部队}
            </div>
            {record.matches.slice(0, 3).map((match, index) => (
              <div
                key={index}
                className="text-gray-600"
                dangerouslySetInnerHTML={{
                  __html: highlightMatch(match, searchKeyword),
                }}
              ></div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">老兵档案列表</h2>
        <div className="space-x-2">
          <Button
            icon={<SearchOutlined />}
            className="bg-purple-400 hover:bg-purple-500 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
            onClick={handleSearchModalOpen}
          >
            搜索
          </Button>
          {user === "admin" && (
            <Button
              icon={<PlusOutlined />}
              className="bg-green-400 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out"
              onClick={() => showModal(null, false, true)}
            >
              添加人员
            </Button>
          )}
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <Table
          columns={columns}
          dataSource={records}
          rowKey="id"
          className="w-full"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "40"],
            locale: {
              items_per_page: "条/页",
              jump_to: "跳至",
              jump_to_confirm: "确认",
              page: "页",
              prev_page: "上一页",
              next_page: "下一页",
              prev_5: "向前 5 页",
              next_5: "向后 5 页",
              prev_3: "向前 3 页",
              next_3: "向后 3 页",
            },
            className: "custom-pagination",
          }}
        />
      </div>

      <Modal
        title={
          <h3 className="text-2xl font-bold text-gray-800">
            {isEditing
              ? "编辑档案信息"
              : isAdding
              ? "添加人员信息"
              : "档案详细信息"}
          </h3>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
        styles={{ height: "60vh" }}
        style={{ top: "20%" }}
      >
        {selectedRecord !== null || isAdding ? modalContent : null}
      </Modal>

      <Modal
        title={<h3 className="text-2xl font-bold text-gray-800">搜索记录</h3>}
        visible={isSearchModalVisible}
        onCancel={handleSearchModalClose}
        footer={null}
        width={600}
        styles={{ padding: 24 }}
        style={{ top: "15%" }}
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        {searchModalContent}
      </Modal>

      <Modal
        title={<h3 className="text-2xl font-bold text-gray-800">确认删除</h3>}
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        onOk={handleDelete}
        okText="确认"
        cancelText="取消"
        styles={{ padding: 24 }}
        style={{ top: "20%" }}
      >
        确认删除“{selectedRecord?.姓名}”的数据？
      </Modal>
    </div>
  );
};

export default RecordList;
