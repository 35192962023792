import React, { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";

const RelationshipGraph = ({ data }) => {
  const chartRef = useRef(null);
  const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    myChart.showLoading();

    // 模拟数据加载
    setTimeout(() => {
      myChart.hideLoading();

      const nodes = [];
      const links = [];
      const categories = [{ name: "被联系" }, { name: "联系人" }];
      const nodeMap = new Map();
      const outgoingContacts = new Map();
      const incomingContacts = new Map();

      // 处理数据,创建节点和边
      data.forEach((person) => {
        if (!nodeMap.has(person.姓名)) {
          nodeMap.set(person.姓名, {
            id: person.姓名,
            name: person.姓名,
            symbolSize: 20,
            category: 0,
            value: 0, // 用于记录出度
          });
        }

        if (person.联系人) {
          const contacts = person.联系人.split(",").map((c) => c.trim());

          contacts.forEach((contact) => {
            if (!nodeMap.has(contact)) {
              nodeMap.set(contact, {
                id: contact,
                name: contact,
                symbolSize: 20,
                category: 0,
                value: 0,
              });
            }
            links.push({
              source: contact,
              target: person.姓名,
              symbol: ["none", "arrow"],
              symbolSize: [5, 8], // 调整箭头大小，[宽度, 长度]
            });

            if (!outgoingContacts.has(contact))
              outgoingContacts.set(contact, new Set());
            outgoingContacts.get(contact).add(person.姓名);

            if (!incomingContacts.has(person.姓名))
              incomingContacts.set(person.姓名, new Set());
            incomingContacts.get(person.姓名).add(contact);

            // 更新出度
            nodeMap.get(contact).value += 1;
          });
        }
      });

      // 更新节点类别、大小并过滤没有连接的节点
      for (let [name, node] of nodeMap.entries()) {
        if (outgoingContacts.has(name) || incomingContacts.has(name)) {
          if (node.value > 0) {
            node.category = 1;
            node.symbolSize = node.value ** 2 * 5 + 15;
          }
          nodes.push(node);
        }
      }

      const option = {
        tooltip: {
          show: false, // 禁用默认的 tooltip
        },
        legend: [
          {
            data: categories.map((a) => a.name),
            orient: "vertical",
            left: "left",
            top: "middle",
          },
        ],
        animationDurationUpdate: 1500,
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            type: "graph",
            layout: "force",
            data: nodes,
            links: links,
            categories: categories,
            roam: true,
            label: {
              show: true,
              position: "right",
              formatter: "{b}",
              fontSize: 12,
              fontWeight: "bold",
            },
            labelLayout: {
              hideOverlap: true,
            },
            scaleLimit: {
              min: 0.4,
              max: 2,
            },
            lineStyle: {
              color: "source",
              curveness: 0.3,
              width: 1, // 减小线条宽度
            },
            edgeSymbol: ["none", "arrow"],
            edgeSymbolSize: [5, 8], // 全局设置箭头大小
            emphasis: {
              focus: "adjacency",
              lineStyle: {
                width: 2,
              },
            },
            force: {
              repulsion: 100,
              edgeLength: [50, 200],
            },
          },
        ],
      };

      myChart.setOption(option);

      // 添加点击事件
      myChart.on("click", function (params) {
        if (params.dataType === "node") {
          const name = params.data.name;
          setSelectedNode({
            name: name,
            outgoing: outgoingContacts.get(name)
              ? Array.from(outgoingContacts.get(name))
              : [],
            incoming: incomingContacts.get(name)
              ? Array.from(incomingContacts.get(name))
              : [],
            value: params.data.value,
          });
        }
      });
    }, 1000); // 模拟1秒的加载时间

    return () => {
      myChart.dispose();
    };
  }, [data]);

  // 添加点击任何位置关闭面板的逻辑
  useEffect(() => {
    const handleClickAnywhere = () => {
      setSelectedNode(null);
    };

    document.addEventListener("mousedown", handleClickAnywhere);
    return () => {
      document.removeEventListener("mousedown", handleClickAnywhere);
    };
  }, []);

  // 阻止信息面板内的点击事件冒泡
  const handleInfoPanelClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="w-full h-screen p-4 relative">
      <h3
        className="text-xl font-bold"
        style={{ position: "absolute", top: "10px", left: "10px", zIndex: 10 }}
      >
        联系人关系图
      </h3>
      <div ref={chartRef} className="w-full h-full" />
      {selectedNode && (
        <div
          className="absolute top-8 right-8 max-w-sm bg-gray-100 rounded-lg shadow-lg p-4 z-10"
          onClick={handleInfoPanelClick}
        >
          <h2 className="text-xl font-bold mb-4">{selectedNode.name}</h2>
          <h3 className="font-bold mt-4 mb-2">主动联系：</h3>
          <ul className="list-disc list-inside">
            {selectedNode.outgoing.map((contact) => (
              <li key={contact}>{contact}</li>
            ))}
          </ul>
          <h3 className="font-bold mt-4 mb-2">被联系：</h3>
          <ul className="list-disc list-inside">
            {selectedNode.incoming.map((contact) => (
              <li key={contact}>{contact}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RelationshipGraph;
