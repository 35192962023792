// WordCloudChart.js
import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import "echarts-wordcloud";

const WordCloudChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data.length > 0) {
      if (!chartRef.current) {
        chartRef.current = echarts.init(
          document.getElementById("wordCloudChart")
        );
      }

      const wordCloudData = data.reduce((acc, record) => {
        const armyUnit = record.入伍部队;
        if (armyUnit) {
          acc[armyUnit] = (acc[armyUnit] || 0) + 1;
        }
        return acc;
      }, {});

      const formattedData = Object.entries(wordCloudData).map(
        ([name, value]) => ({
          name,
          value,
        })
      );

      const option = {
        tooltip: {
          show: true,
        },
        series: [
          {
            type: "wordCloud",
            gridSize: 10,
            sizeRange: [10, 100],
            rotationRange: [-90, 90],
            shape: "circle",
            width: "100%",
            height: "100%",
            drawOutOfBound: true,
            textStyle: {
              normal: {
                color: () =>
                  `rgb(${Math.round(Math.random() * 160)}, ${Math.round(
                    Math.random() * 160
                  )}, ${Math.round(Math.random() * 160)})`,
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
            data: formattedData,
          },
        ],
      };

      chartRef.current.setOption(option);
    }
  }, [data]);

  return (
    <div style={{ position: "relative", width: "100%", height: "400px" }}>
      <h3
        className="text-xl font-bold"
        style={{ position: "absolute", top: "10px", left: "10px", zIndex: 10 }}
      >
        入伍部队
      </h3>
      <div id="wordCloudChart" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default WordCloudChart;
