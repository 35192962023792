import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  ClipboardDocumentListIcon,
  DocumentArrowDownIcon,
  ChartBarIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";

const Sidebar = ({ user, logout, collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout(); // 使用传入的 logout 函数
    navigate("/login");
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const menuItems = [
    { key: "/records", icon: ClipboardDocumentListIcon, label: "信息页面" },
    { key: "/export", icon: DocumentArrowDownIcon, label: "导出页面" },
    { key: "/visualization", icon: ChartBarIcon, label: "可视化页面" },
  ];

  return (
    <div
      className={`fixed left-0 top-0 bottom-0 bg-gray-100 text-gray-700 shadow-lg z-10 transition-all duration-300 ease-in-out ${
        collapsed ? "w-16" : "w-64"
      }`}
    >
      <div className="flex items-center justify-between h-16 px-4 bg-gray-200 border-b border-gray-300">
        {!collapsed && (
          <h2 className="text-xl font-semibold text-gray-700">老兵档案</h2>
        )}
        <button
          onClick={toggleCollapsed}
          className="p-1 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 transition-colors duration-200"
        >
          {collapsed ? (
            <ChevronDoubleRightIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <ChevronDoubleLeftIcon className="h-6 w-6 text-gray-600" />
          )}
        </button>
      </div>
      <nav className="mt-8">
        {menuItems.map((item) => (
          <a
            key={item.key}
            href={item.key}
            onClick={(e) => {
              e.preventDefault();
              navigate(item.key);
            }}
            className={`flex items-center px-4 py-3 text-sm font-medium transition-colors duration-200 ${
              location.pathname === item.key
                ? "bg-gray-200 text-gray-800"
                : "text-gray-600 hover:bg-gray-200 hover:text-gray-800"
            }`}
          >
            <item.icon className="h-5 w-5 mr-3" />
            {!collapsed && <span>{item.label}</span>}
          </a>
        ))}
      </nav>
      <div className="absolute bottom-0 left-0 right-0 border-t border-gray-300">
        <Menu as="div" className="relative">
          <Menu.Button className="flex items-center w-full px-4 py-3 text-sm font-medium text-left text-gray-600 hover:bg-gray-200 hover:text-gray-800 focus:outline-none focus:bg-gray-200 transition-colors duration-200">
            <UserCircleIcon className="h-6 w-6 mr-3" />
            {!collapsed && <span className="truncate">{user || "未登录"}</span>}
          </Menu.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-in"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="absolute bottom-full left-0 w-full bg-white rounded-t-md shadow-lg focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-gray-200 text-gray-800" : "text-gray-700"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={handleLogout}
                  >
                    <ArrowRightOnRectangleIcon className="h-5 w-5 mr-2" />
                    退出登录
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default Sidebar;
