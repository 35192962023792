import React, { useState } from "react";
import { Button, message, Upload, Radio, Progress, Modal } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { fetchRecords, batchImportRecords } from "../api";

const ALLOWED_FIELDS = [
  "id",
  "入伍时间",
  "姓名",
  "性别",
  "年级",
  "院系",
  "学号",
  "籍贯",
  "民族",
  "政治面貌",
  "生日",
  "入伍部队",
  "获奖情况",
  "联系人",
  "联系情况",
  "慰问情况",
  "备注",
  "升学去向",
  "专业",
  "在校学工",
  "实习实践经历",
  "在校荣誉",
  "毕业院系",
  "毕业专业",
  "毕业学历",
  "毕业年份",
  "就业去向",
  "职务_职称",
  "现居住地",
  "电话",
  "微信",
];

const ExportPage = ({ collapsed, user }) => {
  const [exportFormat, setExportFormat] = useState("csv");
  const [importProgress, setImportProgress] = useState(0);
  const [isImporting, setIsImporting] = useState(false);

  const handleExport = async () => {
    try {
      const records = await fetchRecords();
      console.log("Fetched records:", records);

      if (exportFormat === "csv") {
        // CSV 导出逻辑
        const header = ALLOWED_FIELDS.join(",") + "\n";
        const csvContent = records
          .map((record) =>
            ALLOWED_FIELDS.map((field) => {
              const value = record[field] || "";
              return /[",\n]/.test(value)
                ? `"${value.replace(/"/g, '""')}"`
                : value;
            }).join(",")
          )
          .join("\n");

        const blob = new Blob([header + csvContent], {
          type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "records.csv");
      } else {
        // Excel 导出逻辑
        const worksheet = XLSX.utils.json_to_sheet(records);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Records");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(data, "records.xlsx");
      }

      message.success("导出成功");
    } catch (error) {
      console.error("Export error:", error);
      message.error("导出失败: " + error.message);
    }
  };

  const validateRecord = (record) => {
    const unknownFields = Object.keys(record).filter(
      (field) => !ALLOWED_FIELDS.includes(field)
    );
    if (unknownFields.length > 0) {
      throw new Error(`记录包含未定义的字段: ${unknownFields.join(", ")}`);
    }
    if (!record.姓名) {
      throw new Error("记录缺少必填字段: 姓名");
    }
  };

  const isRecordEmpty = (record) => {
    return Object.values(record).every(
      (value) => value === undefined || value === null || value === ""
    );
  };

  const handleImport = async (file) => {
    setIsImporting(true);
    setImportProgress(0);
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rawRecords = XLSX.utils.sheet_to_json(worksheet);

        const records = rawRecords.filter((record) => !isRecordEmpty(record));

        records.forEach((record, index) => {
          try {
            validateRecord(record);
          } catch (error) {
            throw new Error(`第 ${index + 1} 条记录验证失败: ${error.message}`);
          }
        });

        const cleanedRecords = records.map((record) =>
          Object.fromEntries(
            Object.entries(record).filter(([key]) =>
              ALLOWED_FIELDS.includes(key)
            )
          )
        );

        setImportProgress(50); // 设置进度到50%，表示数据处理完成

        try {
          const result = await batchImportRecords(cleanedRecords);
          message.success(
            `导入成功，共导入 ${result.detail.split(" ")[1]} 条数据`
          );
        } catch (error) {
          console.error("Batch import error:", error);
          message.error(`批量导入失败: ${error.message}`);
        }
      } catch (error) {
        console.error("Import error:", error);
        message.error(`导入失败: ${error.message}`);
      } finally {
        setIsImporting(false);
        setImportProgress(100); // 设置进度到100%，表示操作完成
      }
    };
    reader.onerror = (error) => {
      console.error("FileReader error:", error);
      message.error("读取文件失败");
      setIsImporting(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadProps = {
    accept: ".xlsx",
    beforeUpload: (file) => {
      const isXLSX =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXLSX) {
        message.error("只能上传 XLSX 文件！");
      }
      return isXLSX || Upload.LIST_IGNORE;
    },
    customRequest: ({ file, onSuccess }) => {
      handleImport(file);
      onSuccess();
    },
    showUploadList: false,
  };

  return (
    <div className={`p-8 transition-all duration-300`}>
      <div className="flex flex-col mx-auto items-start justify-start">
        <h2 className="text-3xl font-bold mb-8 text-gray-800">
          数据导出和导入
        </h2>
        <div className="flex flex-col bg-white p-8 rounded-lg shadow-md space-y-8 max-w-2xl w-full">
          <h3 className="text-xl font-semibold text-gray-700">导出数据</h3>
          <div className="flex items-center space-x-4">
            <Radio.Group
              value={exportFormat}
              onChange={(e) => setExportFormat(e.target.value)}
            >
              <Radio.Button value="csv" className="text-gray-600">
                CSV
              </Radio.Button>
              <Radio.Button value="xlsx" className="text-gray-600">
                Excel
              </Radio.Button>
            </Radio.Group>
            <Button
              icon={<DownloadOutlined />}
              onClick={handleExport}
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out flex items-center"
            >
              导出
            </Button>
          </div>
        </div>
        {user === "admin" && (
          <div className="flex flex-col bg-white p-8 rounded-lg shadow-md space-y-8 mt-8 max-w-2xl w-full">
            <h3 className="text-xl font-semibold text-gray-700">
              导入数据 (仅支持 XLSX 格式)
            </h3>
            <Upload {...uploadProps}>
              <Button
                icon={<UploadOutlined />}
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out flex items-center"
              >
                选择 XLSX 文件并导入
              </Button>
            </Upload>
          </div>
        )}
      </div>

      <Modal
        title="正在导入数据"
        visible={isImporting}
        footer={null}
        closable={false}
        className="rounded-lg overflow-hidden"
      >
        <Progress
          percent={Math.round(importProgress)}
          status="active"
          strokeColor="#4CAF50"
        />
      </Modal>
    </div>
  );
};

export default ExportPage;
