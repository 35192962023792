// src/App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Layout } from "antd";
import RecordList from "./components/RecordList";
import Login from "./components/Login";
import Sidebar from "./components/Sidebar";
import ExportPage from "./components/ExportPage";
import VisualizationPage from "./components/VisualizationPage";
import "./global.css";

const { Content } = Layout;

function PrivateRoute({ children, user }) {
  const location = useLocation();
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

function AppContent({ user, setUser, logout, collapsed, setCollapsed }) {
  const location = useLocation();
  const showSidebar = user && location.pathname !== "/login";
  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  return (
    <Layout className="h-screen overflow-hidden">
      {showSidebar && (
        <Sidebar
          user={user}
          logout={handleLogout} // 传递 logout 函数
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
      )}
      <Layout
        className={`transition-all duration-300 h-screen ${
          showSidebar ? (collapsed ? "ml-16" : "ml-64") : "ml-0"
        }`}
      >
        <Content className="h-screen bg-gray-100 flex flex-col overflow-hidden">
          <div
            className={`flex-grow flex flex-col ${
              user ? "p-6" : ""
            } overflow-hidden`}
          >
            <Routes>
              <Route
                path="/login"
                element={
                  user ? (
                    <Navigate to="/records" replace />
                  ) : (
                    <div className="flex justify-center items-center h-full bg-gray-100">
                      <Login setUser={setUser} />
                    </div>
                  )
                }
              />
              <Route
                path="/records"
                element={
                  <PrivateRoute user={user}>
                    <div className="flex-grow overflow-auto">
                      <RecordList user={user} collapsed={collapsed} />
                    </div>
                  </PrivateRoute>
                }
              />
              <Route
                path="/export"
                element={
                  <PrivateRoute user={user}>
                    <div className="flex-grow overflow-auto">
                      <ExportPage collapsed={collapsed} user={user} />
                    </div>
                  </PrivateRoute>
                }
              />
              <Route
                path="/visualization"
                element={
                  <PrivateRoute user={user}>
                    <div className="flex-grow overflow-auto">
                      <VisualizationPage collapsed={collapsed} />
                    </div>
                  </PrivateRoute>
                }
              />
              <Route
                path="/"
                element={
                  user ? (
                    <Navigate to="/records" replace />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

function App() {
  const [user, setUser] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const handleSetUser = (username) => {
    setUser(username);
    localStorage.setItem("user", username);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  return (
    <Router>
      <AppContent
        user={user}
        setUser={handleSetUser}
        logout={handleLogout}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
    </Router>
  );
}

export default App;
