import axios from "axios";

const api = axios.create({
  baseURL: "https://api.veteran.variantconst.com",
});

export const fetchRecords = async () => {
  const response = await api.get("/records/", {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  return response.data;
};

export const createRecord = async (record) => {
  const response = await api.post("/records/", record, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  return response.data;
};

export const deleteRecord = async (id) => {
  const response = await api.delete(`/records/${id}/`, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  return response.data;
};

export const updateRecord = async (id, record) => {
  const response = await api.put(`/records/${id}/`, record, {
    headers: {
      Authorization: process.env.REACT_APP_API_KEY,
    },
  });
  return response.data;
};

export const batchImportRecords = async (records) => {
  try {
    const response = await api.post("/records/batch-import/", records, {
      headers: {
        Authorization: process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Batch import error:", error.response || error);
    throw error;
  }
};

// 新增登录接口
export const login = async (username, password) => {
  try {
    const response = await api.post("/login/", { username, password });
    return response.data;
  } catch (error) {
    console.error("Login error:", error.response || error);
    throw error;
  }
};
