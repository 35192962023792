import React, { useEffect, useRef, useCallback } from "react";
import * as echarts from "echarts";
import * as d3 from "d3-hierarchy";

const CirclePackingChart = ({ data }) => {
  const chartRef = useRef(null);

  const renderCirclePackingChart = useCallback((myChart, rawData) => {
    const dataWrap = prepareData(rawData);
    initChart(myChart, dataWrap.seriesData, dataWrap.maxDepth);
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const myChart = echarts.init(chartRef.current);
      renderCirclePackingChart(myChart, data);
    }
  }, [data, renderCirclePackingChart]);

  const prepareData = (rawData) => {
    const seriesData = [
      {
        id: "root",
        name: "总览",
        value: 0,
        depth: 0,
      },
    ];
    let maxDepth = 1;

    function convert(source, parentId, depth) {
      const id = `${parentId}.${source.院系名称}`;
      seriesData.push({
        id: id,
        value: source.学生数量,
        depth: depth,
        name: source.院系名称,
        students: source.学生详情,
      });
      maxDepth = Math.max(depth, maxDepth);

      if (source.子部门 && source.子部门.length > 0) {
        for (let subDept of source.子部门) {
          convert(subDept, id, depth + 1);
        }
      }
    }

    for (let dept of rawData) {
      convert(dept, "root", 1);
    }

    return { seriesData, maxDepth };
  };

  const initChart = (myChart, seriesData, maxDepth) => {
    const stratify = () => {
      return d3
        .stratify()
        .id((d) => d.id)
        .parentId((d) => d.id.substring(0, d.id.lastIndexOf(".")))(seriesData)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value);
    };

    let displayRoot = stratify();

    const overallLayout = (params, api) => {
      const context = params.context;
      d3
        .pack()
        .size([api.getWidth() - 2, api.getHeight() - 2])
        .padding(3)(displayRoot);
      context.nodes = {};
      displayRoot.descendants().forEach((node) => {
        context.nodes[node.id] = node;
      });
    };

    const renderItem = (params, api) => {
      const context = params.context;
      if (!context.layout) {
        context.layout = true;
        overallLayout(params, api);
      }
      const nodePath = api.value("id");
      const node = context.nodes[nodePath];
      if (!node || node.depth === 0) {
        return;
      }
      const nodeName = node.data.name;
      const z2 = api.value("depth") * 2;

      return {
        type: "circle",
        shape: {
          cx: node.x,
          cy: node.y,
          r: node.r,
        },
        transition: ["shape"],
        z2: z2,
        textContent: {
          type: "text",
          style: {
            text: nodeName,
            fontFamily: "Arial",
            width: node.r * 1.3,
            overflow: "truncate",
            fontSize: node.r / 3,
          },
          emphasis: {
            style: {
              overflow: null,
              fontSize: Math.max(node.r / 3, 12),
            },
          },
        },
        textConfig: {
          position: "inside",
        },
        style: {
          fill: api.visual("color"),
        },
        emphasis: {
          style: {
            fontFamily: "Arial",
            fontSize: 12,
            shadowBlur: 20,
            shadowOffsetX: 3,
            shadowOffsetY: 5,
            shadowColor: "rgba(0,0,0,0.3)",
          },
        },
      };
    };

    const option = {
      dataset: {
        source: seriesData,
      },
      tooltip: {
        formatter: (params) => {
          const { name, value, data } = params;
          if (data.depth === 0) return;
          let tooltipContent = `<strong>${name.split(".")[1]}</strong>（${
            value.students.length
          }人）<br/>`;
          if (data.students && data.students.length > 0) {
            data.students.slice(0, 10).forEach((student) => {
              tooltipContent += `${student.姓名} - ${
                student.入伍时间 || "未知"
              } - ${student.入伍部队 || "未知"}<br/>`;
            });
            if (data.students.length > 10) {
              tooltipContent += `... 等共${data.students.length}人`;
            }
          }
          return tooltipContent;
        },
      },
      visualMap: [
        {
          show: false,
          min: 0,
          max: maxDepth,
          dimension: "depth",
          inRange: {
            color: [
              "#f0f9e8",
              "#ccebc5",
              "#a8ddb5",
              "#7bccc4",
              "#43a2ca",
              "#0868ac",
            ],
          },
        },
      ],
      series: {
        type: "custom",
        renderItem: renderItem,
        progressive: 0,
        coordinateSystem: "none",
        encode: {
          tooltip: "value",
          itemName: "id",
        },
      },
    };

    myChart.setOption(option);
  };

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

const StyledCirclePackingChart = ({ data }) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-xl font-bold mb-4 text-left">院系分布</h2>
      <CirclePackingChart data={data} />
    </div>
  );
};

export default StyledCirclePackingChart;
