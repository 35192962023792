// VisualizationPage.js
import React, { useEffect, useState, useCallback, useRef } from "react";
import { fetchRecords } from "../api";
import * as echarts from "echarts";
import CirclePackingChart from "./CirclePackingChart";
import RelationshipGraph from "./RelationshipGraph";
import WordCloudChart from "./WordCloudChart";

const VisualizationPage = () => {
  const [data, setData] = useState([]);
  const [circlePackingData, setCirclePackingData] = useState([]);
  const chartsRef = useRef({});

  useEffect(() => {
    const getData = async () => {
      const records = await fetchRecords();
      setData(records);
      setCirclePackingData(processDataForCirclePacking(records));
    };
    getData();
  }, []);

  const processDataForCirclePacking = (records) => {
    const collegeData = records.reduce((acc, record) => {
      const college = record.院系;
      if (college) {
        if (!acc[college]) {
          acc[college] = { 院系名称: college, 学生数量: 0, 学生详情: [] };
        }
        acc[college].学生数量 += 1;
        acc[college].学生详情.push({
          姓名: record.姓名,
          性别: record.性别,
          年级: record.年级,
          专业: record.专业,
          学号: record.学号,
          入伍时间: record.入伍时间,
          入伍部队: record.入伍部队,
          籍贯: record.籍贯,
          民族: record.民族,
          政治面貌: record.政治面貌,
          联系人: record.联系人,
        });
      }
      return acc;
    }, {});

    return Object.values(collegeData);
  };

  const renderYearChart = useCallback(() => {
    if (!chartsRef.current.yearChart) {
      chartsRef.current.yearChart = echarts.init(
        document.getElementById("yearChart")
      );
    }
    const yearData = data.reduce((acc, record) => {
      const year = record.入伍时间?.slice(0, 4);
      if (year) {
        acc[year] = (acc[year] || 0) + 1;
      }
      return acc;
    }, {});

    const yearChartData = Object.entries(yearData).map(([year, count]) => ({
      year,
      count,
    }));

    const option = {
      title: { text: "入伍时间分布" },
      tooltip: { trigger: "axis" },
      xAxis: { type: "category", data: yearChartData.map((item) => item.year) },
      yAxis: { type: "value" },
      series: [{ data: yearChartData.map((item) => item.count), type: "line" }],
    };
    chartsRef.current.yearChart.setOption(option);
  }, [data]);

  const renderGenderPieChart = useCallback(() => {
    if (!chartsRef.current.genderPieChart) {
      chartsRef.current.genderPieChart = echarts.init(
        document.getElementById("genderPieChart")
      );
    }
    const genderData = data.reduce((acc, record) => {
      const gender = record.性别;
      if (gender) {
        acc[gender] = (acc[gender] || 0) + 1;
      }
      return acc;
    }, {});

    const genderChartData = Object.entries(genderData).map(([name, value]) => ({
      name,
      value,
    }));

    const option = {
      title: { text: "性别分布" },
      tooltip: { trigger: "item", formatter: "{a} <br/>{b}: {c} ({d}%)" },
      series: [
        {
          name: "性别",
          type: "pie",
          radius: "50%",
          data: genderChartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0,0,0,0.5)",
            },
          },
        },
      ],
    };
    chartsRef.current.genderPieChart.setOption(option);
  }, [data]);

  const renderPoliticalStatusChart = useCallback(() => {
    if (!chartsRef.current.politicalStatusChart) {
      chartsRef.current.politicalStatusChart = echarts.init(
        document.getElementById("politicalStatusChart")
      );
    }
    const politicalData = data.reduce((acc, record) => {
      const status = record.政治面貌;
      if (status) {
        acc[status] = (acc[status] || 0) + 1;
      }
      return acc;
    }, {});

    const politicalChartData = Object.entries(politicalData).map(
      ([name, value]) => ({ name, value })
    );

    const option = {
      title: { text: "政治面貌分布" },
      tooltip: { trigger: "item", formatter: "{a} <br/>{b}: {c} ({d}%)" },
      series: [
        {
          name: "政治面貌",
          type: "pie",
          radius: "50%",
          data: politicalChartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0,0,0,0.5)",
            },
          },
        },
      ],
    };
    chartsRef.current.politicalStatusChart.setOption(option);
  }, [data]);

  const renderEthnicityChart = useCallback(() => {
    if (!chartsRef.current.ethnicityChart) {
      chartsRef.current.ethnicityChart = echarts.init(
        document.getElementById("ethnicityChart")
      );
    }
    const ethnicityData = data.reduce((acc, record) => {
      const ethnicity = record.民族;
      if (ethnicity) {
        acc[ethnicity] = (acc[ethnicity] || 0) + 1;
      }
      return acc;
    }, {});

    const ethnicityChartData = Object.entries(ethnicityData)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([name, value]) => ({ name, value }));

    const option = {
      title: { text: "民族分布 (前10)" },
      tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
      xAxis: {
        type: "category",
        data: ethnicityChartData.map((item) => item.name),
      },
      yAxis: { type: "value" },
      series: [
        {
          name: "人数",
          type: "bar",
          data: ethnicityChartData.map((item) => item.value),
        },
      ],
    };
    chartsRef.current.ethnicityChart.setOption(option);
  }, [data]);

  useEffect(() => {
    if (data.length > 0) {
      renderYearChart();
      renderGenderPieChart();
      renderPoliticalStatusChart();
      renderEthnicityChart();
    }
  }, [
    data,
    renderYearChart,
    renderGenderPieChart,
    renderPoliticalStatusChart,
    renderEthnicityChart,
  ]);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold mb-6 text-indigo-700">可视化</h2>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-8">
          <CirclePackingChart data={circlePackingData} />
        </div>
        <div className="col-span-12 lg:col-span-4 grid grid-rows-2 gap-6">
          <div className="bg-white rounded-lg shadow-lg p-4">
            <div className="h-[250px]" id="genderPieChart" />
          </div>
          <div className="bg-white rounded-lg shadow-lg p-4">
            <div className="h-[250px]" id="politicalStatusChart" />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <div className="bg-white rounded-lg shadow-lg p-4">
            <div className="h-[300px]" id="yearChart" />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <div className="bg-white rounded-lg shadow-lg p-4">
            <div className="h-[300px]" id="ethnicityChart" />
          </div>
        </div>
        <div className="col-span-12">
          <div className="bg-white rounded-lg shadow-lg p-4">
            <RelationshipGraph data={data} />
          </div>
        </div>
        <div className="col-span-12">
          <div className="bg-white rounded-lg shadow-lg p-4">
            <WordCloudChart data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisualizationPage;
